import { Link } from 'react-router-dom'
import { GLOBALS } from '../../CONFIG'

function AdminPage() {
  fetch(GLOBALS.urlServer + "/verify/admin",{credentials: 'include'
  })
  return (
    <div>The stuff here only works if you have an admin token
      <br></br>
    <Link to="/login">Get One</Link>
    </div>
  )
}

export default AdminPage