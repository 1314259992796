 import { useEffect } from 'react'
import { Link } from 'react-router-dom'

function Navbar() {

    console.log("navbar")

    useEffect(()=>{
        // console.log("fetchin auth")
        
        // fetch(GLOBALS.BASE_URL + "/verify/admin",{
        //     credentials: 'include'
        // }).then(async (res)=>{
        //     console.log("got response from login check")
        //     console.log(await res.json())
        // }, (reason) => {
        //     console.error(reason)
        // })
        
    },[])
    
   
    return (
        <div>
            <header className='flex justify-between p-4 pl-8'>
                <nav className='home font-bold'>
                    <Link to="./">Home</Link>
                </nav>
                <nav className=''>
                    <Link to="./Login" className='outline'>Login</Link>
                    <br></br>
                    <Link to="./Register"className='outline'
                    >Register</Link>
                </nav>
            </header>
        </div>
    )
}

export default Navbar